// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "bootstrap"
import "../stylesheets/application"

// Set up Bootstrap.
document.addEventListener("turbo:load", () => {
    $('[data-bs-toggle="tooltip"]').tooltip();
    $('[data-bs-toggle="popover"]').popover();

    var tabTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tab"]'))
    var tabList = tabTriggerList.map(function (tabTriggerEl) {
        return new bootstrap.Tab(tabTriggerEl)
    })
});


function defaultBackgroundColor() {
    return "#87cefa";
}

function setUpColorPicker() {
    const colorPicker     = document.getElementById("bgcolor-picker");
    const bgColorResetter = document.getElementById("bgcolor-resetter");

    function setBackgroundColor(color) {
        console.log("Setting background color to " + color);
        colorPicker.setAttribute("value", color);
        document.body.style.background = color;
        localStorage.setItem("background-color", color);
        document.getElementById("bg-color-value-text").innerHTML = color;
    }

    function setInitialColor() {
        let color = localStorage.getItem("background-color") || defaultBackgroundColor();
        setBackgroundColor(color);
    }

    function colorChangeHandler(event) {
        setBackgroundColor(event.target.value);
    }

    function resetBackgroundColor() {
        setBackgroundColor(defaultBackgroundColor());
    }

    setInitialColor();
    colorPicker.addEventListener("input", colorChangeHandler, false);
    colorPicker.addEventListener("change", colorChangeHandler, false);
    bgColorResetter.addEventListener("click", resetBackgroundColor);
}

// DOMContentLoaded event handling:
document.addEventListener('DOMContentLoaded', (event) => {
    setUpColorPicker();
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
